var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('base-card',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"6","lg":"8"}},[_c('v-card-title',[_vm._v("Daily Staff Attendance "),_c('span',[_vm._v("("+_vm._s(_vm.dateString)+")")])])],1),_c('v-col',{attrs:{"cols":"12","md":"6","lg":"4"}},[_c('v-card-title',[_c('v-dialog',{attrs:{"fullscreen":"","hide-overlay":"","transition":"dialog-bottom-transition"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"primary","dark":""}},'v-btn',attrs,false),on),[_vm._v(" Individual Staff Attendance ")])]}}]),model:{value:(_vm.indivisualStaffDialog),callback:function ($$v) {_vm.indivisualStaffDialog=$$v},expression:"indivisualStaffDialog"}},[_c('v-card',[_c('v-toolbar',{attrs:{"dark":"","color":"primary"}},[_c('v-btn',{attrs:{"icon":"","dark":""},on:{"click":function($event){(_vm.indivisualStaffDialog = false),
                        (_vm.selectIndivisualUser = ''),
                        (_vm.firstDate = ''),
                        (_vm.secondDate = ''), 
                        _vm.indivisualAttendanceList=[]}}},[_c('v-icon',[_vm._v("mdi-close")])],1),_c('v-toolbar-title',[_vm._v("Individual Staff Attendance")]),_c('v-spacer')],1),_c('v-card-title',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-select',{attrs:{"items":_vm.branchList,"item-text":"name","item-value":"branch_id","label":"Select Branch","outlined":"","dense":""},model:{value:(_vm.selectIndivisualBranch),callback:function ($$v) {_vm.selectIndivisualBranch=$$v},expression:"selectIndivisualBranch"}})],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-select',{attrs:{"items":_vm.userList,"item-text":"name","item-value":"user_id","label":"Select Staff","outlined":"","dense":""},model:{value:(_vm.selectIndivisualUser),callback:function ($$v) {_vm.selectIndivisualUser=$$v},expression:"selectIndivisualUser"}})],1)],1)],1),_c('v-card-title',{staticClass:"mt-n12"},[_c('v-row',[_c('v-col',{attrs:{"cols":"6"}},[_c('v-dialog',{ref:"dialog1",attrs:{"return-value":_vm.firstDate,"persistent":"","width":"290px"},on:{"update:returnValue":function($event){_vm.firstDate=$event},"update:return-value":function($event){_vm.firstDate=$event}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                        var on = ref.on;
                        var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"From","prepend-icon":"mdi-calendar","readonly":""},model:{value:(_vm.firstDate),callback:function ($$v) {_vm.firstDate=$$v},expression:"firstDate"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.menu2),callback:function ($$v) {_vm.menu2=$$v},expression:"menu2"}},[_c('v-date-picker',{attrs:{"max":_vm.secondDate,"scrollable":""},model:{value:(_vm.firstDate),callback:function ($$v) {_vm.firstDate=$$v},expression:"firstDate"}},[_c('v-spacer'),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){_vm.menu2 = false}}},[_vm._v(" Cancel ")]),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){return _vm.$refs.dialog1.save(_vm.firstDate)}}},[_vm._v(" OK ")])],1)],1)],1),_c('v-col',{attrs:{"cols":"6"}},[_c('v-dialog',{ref:"dialog2",attrs:{"return-value":_vm.secondDate,"persistent":"","width":"290px"},on:{"update:returnValue":function($event){_vm.secondDate=$event},"update:return-value":function($event){_vm.secondDate=$event}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                        var on = ref.on;
                        var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"To","prepend-icon":"mdi-calendar","readonly":""},model:{value:(_vm.secondDate),callback:function ($$v) {_vm.secondDate=$$v},expression:"secondDate"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.menu3),callback:function ($$v) {_vm.menu3=$$v},expression:"menu3"}},[_c('v-date-picker',{attrs:{"min":_vm.firstDate},model:{value:(_vm.secondDate),callback:function ($$v) {_vm.secondDate=$$v},expression:"secondDate"}},[_c('v-spacer'),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){_vm.menu3 = false}}},[_vm._v(" Cancel ")]),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){return _vm.$refs.dialog2.save(_vm.secondDate)}}},[_vm._v(" OK ")])],1)],1)],1)],1)],1),_c('v-card-text',{staticClass:"text-center"},[_c('v-btn',{attrs:{"depressed":"","color":"primary"},on:{"click":_vm.searchIndivisualStaff}},[_vm._v(" Search ")])],1),_c('v-divider'),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('div',{staticClass:"d-flex align-center justify-space-between pr-3"},[_c('v-spacer'),(_vm.indivisualAttendanceList.length != 0)?_c('div',[_c('export-excel',{attrs:{"data":_vm.indivisualAttendanceList,"fields":_vm.json_fields,"name":_vm.indivisualFilename}},[_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                        var on = ref.on;
                        var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({},'v-icon',attrs,false),on),[_vm._v(" mdi-download ")])]}}],null,false,2847180262)},[_c('span',[_vm._v(_vm._s(_vm.$t( "dailyModifierSalesPage.downloadModifierSalesReport" ))+" ")])])],1)],1):_vm._e()],1),_c('v-card-text',[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.indivisualAttendanceList,"page":_vm.page,"items-per-page":_vm.itemsPerPage,"item-key":"attendence_id","hide-default-footer":"","no-data-text":_vm.$t('dailyModifierSalesPage.noData')},on:{"update:page":function($event){_vm.page=$event},"page-count":function($event){_vm.pageCount = $event}},scopedSlots:_vm._u([{key:"item.role",fn:function(ref){
                        var item = ref.item;
return [(item.role == 0)?_c('span',[_vm._v("Owner")]):(item.role == 1)?_c('span',[_vm._v("Cashier")]):(item.role == 2)?_c('span',[_vm._v("Manager")]):(item.role == 3)?_c('span',[_vm._v("Waiter")]):_vm._e()]}}])}),_c('div',{staticClass:"text-center py-2"},[_c('v-pagination',{attrs:{"length":_vm.pageCount},model:{value:(_vm.page),callback:function ($$v) {_vm.page=$$v},expression:"page"}})],1)],1)],1)],1)],1)],1)],1)],1)],1),_c('v-row',{staticClass:"mt-n8"},[_c('v-col',{attrs:{"cols":"7","md":"4","lg":"4"}},[_c('v-card-title',[_c('v-select',{attrs:{"items":_vm.branchList,"item-text":"name","item-value":"branch_id","outlined":"","dense":""},model:{value:(_vm.selectBranch),callback:function ($$v) {_vm.selectBranch=$$v},expression:"selectBranch"}})],1)],1),_c('v-col',{attrs:{"cols":"2","md":"6","lg":"7"}}),_c('v-col',{attrs:{"cols":"3","md":"2","lg":"1"}},[_c('v-card-title',[_c('v-dialog',{ref:"dialog",attrs:{"return-value":_vm.date,"persistent":"","width":"290px"},on:{"update:returnValue":function($event){_vm.date=$event},"update:return-value":function($event){_vm.date=$event}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                        var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":""}},on),[_c('v-icon',[_vm._v("mdi-calendar")])],1)]}}]),model:{value:(_vm.modal),callback:function ($$v) {_vm.modal=$$v},expression:"modal"}},[_c('v-date-picker',{attrs:{"scrollable":""},model:{value:(_vm.date),callback:function ($$v) {_vm.date=$$v},expression:"date"}},[_c('v-spacer'),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){return _vm.$refs.dialog.save(_vm.date)}}},[_vm._v(" "+_vm._s(_vm.$t("salesSummaryPage.choose"))+" ")])],1)],1)],1)],1)],1),_c('v-row',{staticClass:"mt-n10"},[_c('v-col',{attrs:{"cols":"12"}},[_c('base-card',[_c('div',{staticClass:"d-flex align-center justify-space-between pr-3"},[_c('v-spacer'),(_vm.salesList.length != 0)?_c('div',[_c('export-excel',{attrs:{"data":_vm.salesList,"fields":_vm.json_fields,"name":_vm.filename}},[_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                        var on = ref.on;
                        var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({},'v-icon',attrs,false),on),[_vm._v(" mdi-download ")])]}}],null,false,2847180262)},[_c('span',[_vm._v(_vm._s(_vm.$t( "dailyModifierSalesPage.downloadModifierSalesReport" ))+" ")])])],1)],1):_vm._e()],1),_c('v-card-text',[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.salesList,"page":_vm.page,"items-per-page":_vm.itemsPerPage,"item-key":"attendence_id","hide-default-footer":"","no-data-text":_vm.$t('dailyModifierSalesPage.noData')},on:{"update:page":function($event){_vm.page=$event},"page-count":function($event){_vm.pageCount = $event}},scopedSlots:_vm._u([{key:"item.role",fn:function(ref){
                        var item = ref.item;
return [(item.role == 0)?_c('span',[_vm._v("Owner")]):(item.role == 1)?_c('span',[_vm._v("Cashier")]):(item.role == 2)?_c('span',[_vm._v("Manager")]):(item.role == 3)?_c('span',[_vm._v("Waiter")]):_vm._e()]}}])}),_c('div',{staticClass:"text-center py-2"},[_c('v-pagination',{attrs:{"length":_vm.pageCount},model:{value:(_vm.page),callback:function ($$v) {_vm.page=$$v},expression:"page"}})],1)],1)],1)],1)],1),_c('v-snackbar',{attrs:{"color":"warning","timeout":_vm.timeout},scopedSlots:_vm._u([{key:"action",fn:function(ref){
                        var attrs = ref.attrs;
return [_c('v-btn',_vm._b({attrs:{"text":""},on:{"click":function($event){_vm.snackbar = false}}},'v-btn',attrs,false),[_vm._v(" Close ")])]}}]),model:{value:(_vm.snackbar),callback:function ($$v) {_vm.snackbar=$$v},expression:"snackbar"}},[_vm._v(" "+_vm._s(_vm.text)+" ")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }