<template>
  <v-row>
    <v-col cols="12">
      <base-card>
        <v-row>
          <v-col cols="12" md="6" lg="8"
            ><v-card-title
              >Daily Staff Attendance
              <span>({{ dateString }})</span></v-card-title
            ></v-col
          >

          <v-col cols="12" md="6" lg="4"
            ><v-card-title
              ><v-dialog
                v-model="indivisualStaffDialog"
                fullscreen
                hide-overlay
                transition="dialog-bottom-transition"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn color="primary" dark v-bind="attrs" v-on="on">
                    Individual Staff Attendance
                  </v-btn>
                </template>
                <v-card>
                  <v-toolbar dark color="primary">
                    <v-btn
                      icon
                      dark
                      @click="
                        (indivisualStaffDialog = false),
                          (selectIndivisualUser = ''),
                          (firstDate = ''),
                          (secondDate = ''), 
                          indivisualAttendanceList=[]
                      "
                    >
                      <v-icon>mdi-close</v-icon>
                    </v-btn>
                    <v-toolbar-title
                      >Individual Staff Attendance</v-toolbar-title
                    >
                    <v-spacer></v-spacer>
                  </v-toolbar>
                  <v-card-title>
                    <v-row>
                      <v-col cols="12" md="6">
                        <v-select
                          v-model="selectIndivisualBranch"
                          :items="branchList"
                          item-text="name"
                          item-value="branch_id"
                          label="Select Branch"
                          outlined
                          dense
                        ></v-select>
                      </v-col>
                      <v-col cols="12" md="6">
                        <v-select
                          v-model="selectIndivisualUser"
                          :items="userList"
                          item-text="name"
                          item-value="user_id"
                          label="Select Staff"
                          outlined
                          dense
                        ></v-select>
                      </v-col>
                    </v-row>
                  </v-card-title>

                  <v-card-title class="mt-n12">
                    <v-row>
                      <v-col cols="6">
                        <v-dialog
                          ref="dialog1"
                          v-model="menu2"
                          :return-value.sync="firstDate"
                          persistent
                          width="290px"
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                              v-model="firstDate"
                              label="From"
                              prepend-icon="mdi-calendar"
                              readonly
                              v-bind="attrs"
                              v-on="on"
                            ></v-text-field>
                          </template>
                          <v-date-picker
                            v-model="firstDate"
                            :max="secondDate"
                            scrollable
                          >
                            <v-spacer></v-spacer>
                            <v-btn text color="primary" @click="menu2 = false">
                              Cancel
                            </v-btn>
                            <v-btn
                              text
                              color="primary"
                              @click="$refs.dialog1.save(firstDate)"
                            >
                              OK
                            </v-btn>
                          </v-date-picker>
                        </v-dialog>
                      </v-col>
                      <v-col cols="6">
                        <v-dialog
                          ref="dialog2"
                          v-model="menu3"
                          :return-value.sync="secondDate"
                          persistent
                          width="290px"
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                              v-model="secondDate"
                              label="To"
                              prepend-icon="mdi-calendar"
                              readonly
                              v-bind="attrs"
                              v-on="on"
                            ></v-text-field>
                          </template>
                          <v-date-picker v-model="secondDate" :min="firstDate">
                            <v-spacer></v-spacer>
                            <v-btn text color="primary" @click="menu3 = false">
                              Cancel
                            </v-btn>
                            <v-btn
                              text
                              color="primary"
                              @click="$refs.dialog2.save(secondDate)"
                            >
                              OK
                            </v-btn>
                          </v-date-picker>
                        </v-dialog>
                      </v-col>
                    </v-row>
                  </v-card-title>

                  <v-card-text class="text-center">
                    <v-btn
                      depressed
                      @click="searchIndivisualStaff"
                      color="primary"
                    >
                      Search
                    </v-btn>
                  </v-card-text>
                  <v-divider></v-divider>
                  <v-row>
                    <v-col cols="12">
                      
                        <div
                          class="d-flex align-center justify-space-between pr-3"
                        >
                          <v-spacer></v-spacer>
                          <div v-if="indivisualAttendanceList.length != 0">
                            <export-excel
                              :data="indivisualAttendanceList"
                              :fields="json_fields"
                              :name="indivisualFilename"
                            >
                              <v-tooltip left>
                                <template v-slot:activator="{ on, attrs }">
                                  <v-icon v-bind="attrs" v-on="on">
                                    mdi-download
                                  </v-icon>
                                </template>
                                <span
                                  >{{
                                    $t(
                                      "dailyModifierSalesPage.downloadModifierSalesReport"
                                    )
                                  }}
                                </span>
                              </v-tooltip>
                            </export-excel>
                          </div>
                        </div>
                        <v-card-text>
                          <v-data-table
                            :headers="headers"
                            :items="indivisualAttendanceList"
                            :page.sync="page"
                            :items-per-page="itemsPerPage"
                            @page-count="pageCount = $event"
                            item-key="attendence_id"
                            hide-default-footer
                            :no-data-text="$t('dailyModifierSalesPage.noData')"
                          >
                            <template v-slot:item.role="{ item }">
                              <span v-if="item.role == 0">Owner</span>
                              <span v-else-if="item.role == 1">Cashier</span>
                              <span v-else-if="item.role == 2">Manager</span>
                              <span v-else-if="item.role == 3">Waiter</span>
                            </template>
                          </v-data-table>
                          <div class="text-center py-2">
                            <v-pagination
                              v-model="page"
                              :length="pageCount"
                            ></v-pagination>
                          </div>
                        </v-card-text>
                      
                    </v-col>
                  </v-row>
                </v-card> </v-dialog></v-card-title
          ></v-col>
        </v-row>
        <v-row class="mt-n8">
          <v-col cols="7" md="4" lg="4">
            <v-card-title>
              <v-select
                v-model="selectBranch"
                :items="branchList"
                item-text="name"
                item-value="branch_id"
                outlined
                dense
              ></v-select
            ></v-card-title>
          </v-col>
          <v-col cols="2" md="6" lg="7"></v-col>
          <v-col cols="3" md="2" lg="1">
            <v-card-title>
              <v-dialog
                ref="dialog"
                v-model="modal"
                :return-value.sync="date"
                persistent
                width="290px"
              >
                <template v-slot:activator="{ on }">
                  <v-btn v-on="on" icon>
                    <v-icon>mdi-calendar</v-icon>
                  </v-btn>
                </template>
                <v-date-picker v-model="date" scrollable>
                  <v-spacer></v-spacer>
                  <v-btn text color="primary" @click="$refs.dialog.save(date)">
                    {{ $t("salesSummaryPage.choose") }}
                  </v-btn>
                </v-date-picker>
              </v-dialog>
            </v-card-title>
          </v-col>
        </v-row>
        <v-row class="mt-n10">
          <v-col cols="12">
            <base-card>
              <div class="d-flex align-center justify-space-between pr-3">
                <v-spacer></v-spacer>
                <div v-if="salesList.length != 0">
                  <export-excel
                    :data="salesList"
                    :fields="json_fields"
                    :name="filename"
                  >
                    <v-tooltip left>
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon v-bind="attrs" v-on="on"> mdi-download </v-icon>
                      </template>
                      <span
                        >{{
                          $t(
                            "dailyModifierSalesPage.downloadModifierSalesReport"
                          )
                        }}
                      </span>
                    </v-tooltip>
                  </export-excel>
                </div>
              </div>
              <v-card-text>
                <v-data-table
                  :headers="headers"
                  :items="salesList"
                  :page.sync="page"
                  :items-per-page="itemsPerPage"
                  @page-count="pageCount = $event"
                  item-key="attendence_id"
                  hide-default-footer
                  :no-data-text="$t('dailyModifierSalesPage.noData')"
                >
                  <template v-slot:item.role="{ item }">
                    <span v-if="item.role == 0">Owner</span>
                    <span v-else-if="item.role == 1">Cashier</span>
                    <span v-else-if="item.role == 2">Manager</span>
                    <span v-else-if="item.role == 3">Waiter</span>
                  </template>
                </v-data-table>
                <div class="text-center py-2">
                  <v-pagination
                    v-model="page"
                    :length="pageCount"
                  ></v-pagination>
                </div>
              </v-card-text>
            </base-card>
          </v-col>
        </v-row>
        <v-snackbar v-model="snackbar" color="warning" :timeout="timeout">
          {{ text }}

          <template v-slot:action="{ attrs }">
            <v-btn text v-bind="attrs" @click="snackbar = false"> Close </v-btn>
          </template>
        </v-snackbar>
      </base-card>
    </v-col>
  </v-row>
</template>
      <script>
import { BASEURL } from "@/api/baseurl";
import axios from "axios";
import TestCard from "@/components/card/TestCard";
import PriceCard from "@/components/card/PriceCard";
import VueHtml2pdf from "vue-html2pdf";

export default {
  name: "Sales",
  metaInfo: {
    // title will be injected into parent titleTemplate
    title: "Sales",
  },
  components: {
    "test-card": TestCard,
    "price-card": PriceCard,
    VueHtml2pdf,
  },
  data() {
    return {
      json_fields: {
        Staff: "user_name",
        Branch: "branch_name",
        Role: {
          field: "role",
          callback: (value) => {
            if (value == 0) {
              return "Owner";
            } else if (value == 1) {
              return "Cashier";
            } else if (value == 3) {
              return "Manager";
            } else if (value == 4) {
              return "Waiter";
            }
          },
        },

        "Clock In": "clock_in_at",
        "Clock Out": "clock_out_at",
        Duration: "time",
      },
      domain: BASEURL,
      company_id: "",
      showIcon: false,
      showChart: false,
      date: "",
      modal: false,
      indivisualStaffDialog: false,
      branchList: [],
      salesList: [],
      expanded: [],
      userList: [],
      menu2: false,
      firstDate: "",
      secondDate: "",
      indivisualAttendanceList:[],
      snackbar: false,
      text: "Please make sure all the field is fill.",
      timeout: 2000,
      menu3: false,
      categoriesList: [],
      modifierList: [],
      modifierItemList: [],
      selectBranch: "",
      selectIndivisualBranch: "",
      selectIndivisualUser: "",
      page: 1,
      pageCount: 0,
      itemsPerPage: 10,
    };
  },
  computed: {
    filename() {
      return "Staff Attendance Report_" + this.date + ".xls";
    },

    indivisualFilename(){
      return "Indivisual Attendance Report_"+  this.firstDate + " to " + this.secondDate+ ".xls"
    },

    dateString() {
      return this.date;
    },

    headers() {
      return [
        {
          text: "Staff",
          align: "start",
          sortable: true,
          value: "user_name",
        },

        {
          text: this.$t("salesSummaryPage.branch"),
          align: "start",
          sortable: false,
          value: "branch_name",
        },

        {
          text: "Role",
          align: "start",
          sortable: false,
          value: "role",
        },

        {
          text: "Clock In",
          align: "start",
          value: "clock_in_at",
          sortable: true,
        },

        {
          text: "Clock Out",
          align: "start",
          value: "clock_out_at",
          sortable: true,
        },

        {
          text: "Duration",
          align: "start",
          value: "time",
          sortable: true,
        },
      ];
    },
  },
  watch: {
    date() {
      this.salesList = [];
      this.getAllStaffAttendence();
    },
    selectBranch() {
      this.salesList = [];
      this.getAllStaffAttendence();
    },
    selectIndivisualBranch() {
      this.userList = [];
      this.getBranchUserList();
      this.selectIndivisualUser = "";
    },
  },
  created() {
    var CryptoJS = require("crypto-js");
    var x = localStorage.getItem("userInfo");
    // Decrypt
    var bytes = CryptoJS.AES.decrypt(x, "secret key 123");
    var decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
    this.companyID = decryptedData.company_id;
    this.getCompanyBranch();
  },
  methods: {
    getBranchUserList() {
      const params = new URLSearchParams();
      params.append("getBranchUserList", "done");
      params.append("branch_id", this.selectIndivisualBranch);
      axios({
        method: "post",
        url: this.domain + "/user/index.php",
        data: params,
      })
        .then((response) => {
          console.log(response);
          if (response.data.status == "1") {
            this.userList = response.data.user;
          } else {
            this.userList = [];
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },

    getAllStaffAttendence() {
      const params = new URLSearchParams();
      params.append("getAllStaffAttendence", "done");
      params.append("branch_id", this.selectBranch);
      params.append("date", this.date);

      axios({
        method: "post",
        url: this.domain + "/report/index.php",
        data: params,
      })
        .then((response) => {
          console.log(response);
          if (response.data.status == "1") {
            this.salesList = response.data.report;
          } else {
            this.salesList = [];
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },

    searchIndivisualStaff() {
      if (
        (this.selectIndivisualBranch == "") |
        (this.selectIndivisualUser == "") |
        (this.firstDate == "") |
        (this.secondDate == "")
      ) {
        this.snackbar = true;
      } else {
        const params = new URLSearchParams();
        params.append("getIndivisualStaffAttendance", "done");
        params.append("branch_id", this.selectIndivisualBranch);
        params.append("user_id", this.selectIndivisualUser);
        params.append("first_date", this.firstDate);
        params.append("second_date", this.secondDate);

        axios({
          method: "post",
          url: this.domain + "/report/index.php",
          data: params,
        })
          .then((response) => {
            console.log(response);
            if (response.data.status == "1") {
              this.indivisualAttendanceList = response.data.report;
            } else {
              this.indivisualAttendanceList = [];
            }
          })
          .catch((error) => {
            console.log(error);
          });
      }
    },

    getCompanyBranch() {
      const params = new URLSearchParams();
      params.append("getCompanyBranch", "done");
      params.append("company_id", this.companyID);

      axios({
        method: "post",
        url: this.domain + "/branch/index.php",
        data: params,
      })
        .then((response) => {
          console.log(response);
          if (response.data.status == "1") {
            this.branchList = response.data.branch;
            var moment = require("moment");
            this.date = moment().format("YYYY-MM-DD");
            this.selectBranch = this.branchList[0].branch_id;
            this.selectIndivisualBranch = this.branchList[0].branch_id;
            this.getAllStaffAttendence();
            this.getBranchUserList();
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
};
</script>


      
      
      